<template>
  <div class="base-info">
    <Title :icon="icon" title="合同资产详情"> </Title>
    <div style="padding: 12px">
      <a-row type="flex" align="middle">
        <a-col :span="18">
          <a-descriptions bordered :column="3" size="small">

            <a-descriptions-item label="工程名称" :span="3">
              {{ detail.contractName }}
            </a-descriptions-item>

            <a-descriptions-item label="设计编号">
              {{ detail.designCode }}
            </a-descriptions-item>
            <a-descriptions-item label="项目状态">
              {{ detail.statusName }}
            </a-descriptions-item>
            <a-descriptions-item label="合同金额">
              <Money :money="detail.contractAmountNj" />万元
            </a-descriptions-item>

            <a-descriptions-item label="客户名称">
              {{ detail.partyAname }}
            </a-descriptions-item>
            <a-descriptions-item label="产值进度">
              <span style="color: #1890ff">
                {{ detail.projectProgress }}%
              </span>
            </a-descriptions-item>
            <a-descriptions-item label="项目类型">
              {{ detail.projectTypeName }}
            </a-descriptions-item>
            <a-descriptions-item label="项目组合">
              {{ detail.phases }}
            </a-descriptions-item>
            <a-descriptions-item label="合同收款结算进度">
              <span style="color: #1890ff">
                {{ detail.receiptProgress }}%
              </span>
            </a-descriptions-item>

            <a-descriptions-item label="生产负责人">
              <div class="left">
                <span style="margin-right: 4px">
                  {{ detail.productionMasterName }}
                </span>
                <EmployeeSelector @change="(arr) => {
                                  if (arr.length) {
                                    changeUser('productionMasterName', arr[0]);
                                  }
                                }
                                  ">
                  <a href="#" v-if="$getPermission($route.path + '/edit')" @click.prevent class="edit">修改</a>
                </EmployeeSelector>
              </div>
            </a-descriptions-item>

            <a-descriptions-item label="区域负责人">
              <div class="left">
                <span style="margin-right: 4px">
                  {{ detail.areaMasterName }}
                </span>

                <EmployeeSelector @change="(arr) => {
                                  if (arr.length) {
                                    changeUser('areaMasterName', arr[0]);
                                  }
                                }
                                  ">
                  <a href="#" v-if="$getPermission($route.path + '/edit')" @click.prevent class="edit">修改</a>
                </EmployeeSelector>
              </div>
            </a-descriptions-item>


            <a-descriptions-item label="现场负责人">
              <div class="left">
                <span style="margin-right: 4px">
                  {{ detail.siteLeaderName }}
                </span>
                <EmployeeSelector @change="(arr) => {
                                  if (arr.length) {
                                    changeUser('siteLeader', arr[0]);
                                  }
                                }
                                  ">
                  <a href="#" v-if="$getPermission($route.path + '/edit')" @click.prevent class="edit">修改</a>
                </EmployeeSelector>
              </div>

            </a-descriptions-item>

            <a-descriptions-item label="分管院长">

              <div class="left">
                <span style="margin-right: 4px">
                  {{ detail.chargeMasterName }}
                </span>
                <EmployeeSelector @change="(arr) => {
                                  if (arr.length) {
                                    changeUser('chargeMaster', arr[0]);
                                  }
                                }
                                  ">
                  <a href="#" v-if="$getPermission($route.path + '/edit')" @click.prevent class="edit">修改</a>
                </EmployeeSelector>
              </div>

            </a-descriptions-item>


            <a-descriptions-item label="主要负责">
              <div class="left">
                <span v-if="detail.masterId === detail.productionMaster" style="margin-right: 4px">
                  {{ detail.productionMasterName }}
                </span>
                <span v-if="detail.masterId === detail.areaMaster" style="margin-right: 4px">
                  {{ detail.areaMasterName }}
                </span>

                <EditMaster v-if="$getPermission($route.path + '/edit')" :detail="detail" @refresh="$emit('refresh')" />
              </div>
            </a-descriptions-item>
          </a-descriptions>
        </a-col>

        <a-col :span="6">
          <Chart :detail="detail" />
          <div class="list">
            <!-- <div class="left item">
              <span> 应开票金额： </span>
              <Money :money="detail.needInvoiceSum" />
              <span> 万元 </span>
            </div> -->
            <div class="left item">
              <div class="legend" style="background: #1890ff"></div>
              <span> 已开票金额： </span>
              <span style="min-width: 4vw">
                <Money :money="detail.invoiceSum" />
              </span>
              <span> 万元 </span>
            </div>
            <div class="left item">
              <div class="legend" style="background: #f0f0f0"></div>
              <span> 未开票金额： </span>
              <span style="min-width: 4vw">
                <Money :money="detail.notInvoiceSum" />
              </span>
              <span> 万元 </span>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import Title from "../title";
import EmployeeSelector from "@/components/employee-selector";
import EditMaster from "./edit-master.vue";
import Chart from "./chart.vue";
import { edit } from "@/api/finance/contract-assets";
export default {
  components: {
    Title,
    EmployeeSelector,
    EditMaster,
    Chart,
  },
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      icon: require("@/assets/accounts-receivable/icon-1.png"),
    };
  },

  methods: {
    changeUser(type, user) {
      const data = {
        ...this.detail,
      };
      if (type === "productionMasterName") {
        data.productionMaster = user.userId;
        data.productionMasterName = user.name;
      } else if (type === "areaMasterName") {
        data.areaMaster = user.userId;
        data.areaMasterName = user.name;
      } else if (type === 'chargeMaster') {
        data.chargeMaster = user.userId;
        data.chargeMasterName = user.name;
      } else if (type === 'siteLeader') {
        data.siteLeader = user.userId;
        data.siteLeaderName = user.name
      }
      edit(data).then(() => {
        this.$emit("refresh");
      });
    },
  },
};
</script>

<style lang="less" scoped>
.base-info {
  background-color: #fff;
  margin: 8px;
}

.list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px 48px 0;

  .item {
    margin-bottom: 4px;

    .legend {
      height: 1em;
      width: 1em;
      margin-right: 4px;
    }
  }
}
</style>