<template>
  <div class="title-wrapper">
    <img v-if="icon" :src="icon" alt="" />
    <div class="title">{{ title }}</div>
    <div class="extra">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  props: ["title", "icon"],
};
</script>
<style lang="less" scoped>
.title-wrapper {
  display: flex;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid #f0f0f0;

  img {
    height: 15px;
    margin-right: 8px;
  }
  .title {
    font-size: 14px;
    color: #1890ff;
    font-weight: bold;
  }

  .extra {
    margin-left: auto;
  }
}
</style>