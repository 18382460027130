<template>
  <div class="info">
    <Title :icon="icon" title="合同资产详情">
      <a-button v-if="$getPermission($route.path + '/edit')" type="primary" size="small"
        @click="visible = true">已开票金额调整</a-button>
    </Title>
    <div style="padding: 12px">
      <div class="list">
        <div class="item">
          <div>时间</div>
          <div>子项目</div>
          <div>本月可确认收入</div>
          <div>本月开票额</div>
          <div>本月开票调整额</div>
          <div>累计已开票金额</div>
          <div>本月确认合同资产</div>
          <div>累计合同资产</div>
          <div>本月确认应开票金额</div>
          <div>累计应开票金额</div>
        </div>
        <div class="item" v-for="item in list" :key="item.id">
          <div style="color: #1890ff">{{ item.month }}</div>
          <div class="projects">
            <template v-if="Array.isArray(item.projectList)">
              <div v-for="element in item.projectList" :key="element.id" style="color: #1890ff">
                {{ element.projectCode }}
              </div>
            </template>
          </div>
          <div class="projects">
            <template v-if="Array.isArray(item.projectList)">
              <div v-for="element in item.projectList" :key="element.id">
                {{
                                element.confirmedAmountMonth
                                ? element.confirmedAmountMonth.toLocaleString() + "元"
                                : "--"
                                }}
              </div>
            </template>
          </div>
          <div>
            {{
                        item.invoiceAmountMonth
                        ? item.invoiceAmountMonth.toLocaleString() + "元"
                        : "--"
                        }}
          </div>

          <div>
            {{
                        item.invoiceChangeAmount
                        ? item.invoiceChangeAmount.toLocaleString() + "元"
                        : "--"
                        }}
          </div>

          <div>
            {{
                        item.invoiceAmount
                        ? item.invoiceAmount.toLocaleString() + "元"
                        : "--"
                        }}
          </div>

          <div>
            {{
                        item.assetAmountMonth
                        ? item.assetAmountMonth.toLocaleString() + "元"
                        : "--"
                        }}
          </div>
          <div>
            {{
                        item.assetAmount ? item.assetAmount.toLocaleString() + "元" : "--"
                        }}
          </div>
          <div>
            {{
                        item.shouldInvoiceAmountMonth
                        ? item.shouldInvoiceAmountMonth.toLocaleString() + "元"
                        : "--"
                        }}
          </div>
          <div>
            {{
                        item.shouldInvoiceAmount
                        ? item.shouldInvoiceAmount.toLocaleString() + "元"
                        : "--"
                        }}
          </div>
        </div>
      </div>
    </div>

    <a-modal title="已开票金额调整" :visible="visible" :footer="null" @cancel="cancel">
      <a-form :form="form" :colon="false" @submit="handleSubmit">
        <a-form-item label="调整发生时间">
          <a-date-picker style="width: 100%" placeholder="" v-decorator="[
                          'changeTime',
                          { rules: [{ required: true, message: '请选择！' }] },
                        ]" />
        </a-form-item>
        <a-form-item label="调整金额(元)">
          <a-input-number :formatter="$inputNumberFormat" style="width: 100%" v-decorator="[
                          'changeAmount',
                          {
                            rules: [{ required: true, message: '请输入！' }],
                          },
                        ]" />
        </a-form-item>
        <div class="right">
          <a-space>
            <a-button @click="cancel">取消</a-button>
            <a-button type="primary" htmlType="submit" :loading="loading">提交</a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import Title from "../title";
import { changeInvoice } from "@/api/finance/contract-assets";
export default {
  components: {
    Title,
  },

  props: {
    list: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
    },
  },

  data() {
    return {
      icon: require("@/assets/accounts-receivable/icon-2.png"),
      form: this.$form.createForm(this),
      visible: false,
      loading: false,
    };
  },

  methods: {
    cancel() {
      this.visible = false;
      this.form.resetFields();
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true;
          changeInvoice({
            ...values,
            changeTime: values.changeTime.format("YYYY-MM-DD"),
            assetId: this.id,
          })
            .then(() => {
              this.cancel();
              this.$emit("refresh");
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.info {
  background-color: #fff;
  margin: 8px;
}

.list {
  border-top: 1px solid #f0f0f0;
  border-left: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;

  .item {
    display: flex;
    border-bottom: 1px solid #f0f0f0;

    &>div {
      flex: 1;
      padding: 8px 0;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #f0f0f0;
    }

    .projects {
      display: grid;
      grid-template-columns: 1fr;
      background-color: #f0f0f0;
      gap: 1px;
      padding: 0;

      div {
        padding: 8px;
        background-color: #fff;
      }
    }
  }

  .item:first-child {
    font-weight: bold;
    background-color: #f9f9f9;
  }
}
</style>