<template>
  <div>
    <a href="#" @click.prevent="visible = true">修改</a>
    <a-modal title="修改主要负责" :visible="visible" @cancel="cancel" @ok="ok">
      <a-radio-group v-model="selected">
        <a-radio value="productionMaster">设计负责人</a-radio>
        <a-radio value="areaMaster">区域负责人</a-radio>
      </a-radio-group>
    </a-modal>
  </div>
</template>
<script>
import { edit } from "@/api/finance/contract-assets";
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      selected: null,
      visible: false,
    };
  },

  watch: {
    detail() {
      if (this.detail.masterId === this.detail.productionMaster) {
        this.selected = "productionMaster";
      }
      if (this.detail.masterId === this.detail.areaMaster) {
        this.selected = "areaMaster";
      }
    },
  },

  methods: {
    cancel() {
      this.visible = false;
    },
    ok() {
      const data = {
        ...this.detail,
      };

      if (this.selected === "productionMaster") {
        data.masterId = this.detail.productionMaster;
      } else if (this.selected === "areaMaster") {
        data.masterId = this.detail.areaMaster;
      }
      edit(data).then(() => {
        this.$emit("refresh");
        this.visible = false;
      });
    },
  },
};
</script>