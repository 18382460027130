<template>
  <div class="plan">
    <Title :icon="icon" title="回款计划"> </Title>
    <div style="padding: 12px 48px">
      <template v-if="list.length > 0">
        <div class="plan-title">合同资产开始回款</div>

        <div class="list">
          <div class="item" v-for="(item, index) in list" :key="item.id">
            <div class="primary-card" v-if="item.content">
              <div class="between header">
                <div class="title">{{ item.userName }}</div>
                <div class="date">{{ item.fillingTime }}</div>
              </div>
              <div class="content">
                <div>{{ item.content }}</div>
                <div class="left footer">
                  <span>金额：</span>
                  <span class="value">{{ item.invoiceAmount }}元</span>
                  <span>时间：</span>
                  <span class="value">{{ item.invoiceTime }}</span>
                  <a-button size="small" type="primary" class="fold-button" @click="fold(index)">
                    <template v-if="Array.isArray(item.trackList)">
                      <template v-if="item.fold">
                        <span>展开</span>
                        <a-icon class="rotate" type="double-right" />
                      </template>
                      <template v-else>
                        <span>收起</span>
                        <a-icon class="icon" type="double-right" />
                      </template>
                    </template>
                  </a-button>
                </div>
              </div>
              <div class="info">
                <div>{{ item.periodFrom }}</div>
                <div>计划</div>
              </div>
              <div class="index">
                {{ index + 1 }}
              </div>
              <img :src="arrow" class="arrow" alt="" />

              <template v-if="Array.isArray(item.trackList) && !item.fold">
                <div v-for="element in item.trackList" :key="element.id">
                  <div class="tip" v-if="element.typeName === '系统提醒'">
                    <div class="content">{{ element.content }}</div>
                    <div class="info">
                      <div>{{ getDate(element.createAt) }}</div>
                      <div>{{ element.typeName }}</div>
                    </div>
                    <div class="index"></div>
                    <img :src="arrow" class="arrow" alt="" />
                  </div>

                  <div class="tip money" v-else-if="element.typeName === '回款'">
                    <div class="content">{{ element.content }}</div>
                    <div class="info">
                      <div>{{ getDate(element.createAt) }}</div>
                      <div>{{ element.typeName }}</div>
                    </div>
                    <div class="index"></div>
                    <img :src="arrow" class="arrow" alt="" />
                  </div>

                  <template v-else>
                    <div class="default-card">
                      <div class="between header">
                        <div class="title">{{ element.fillerName }}</div>
                        <div class="date">{{ element.createAt }}</div>
                      </div>
                      <div class="left content">
                        <span>
                          {{ element.content }}
                        </span>
                        <template v-if="Array.isArray(element.assistTrackList)">
                          <span style="color: #1890ff; margin-left: 4px">
                            @
                            {{
                              element.assistTrackList
                                .map((item) => item.fillerName)
                                .join()
                            }}</span>
                        </template>
                      </div>
                      <div class="info">
                        <div>{{ getDate(element.createAt) }}</div>
                        <div>{{ element.typeName }}</div>
                      </div>
                      <div class="index"></div>
                      <img :src="arrow" class="arrow" alt="" />
                    </div>

                    <template v-if="Array.isArray(element.assistTrackList)">
                      <div class="default-card child" v-for="child in element.assistTrackList" :key="child.id">
                        <div class="left header">
                          <div class="title">{{ child.fillerName }}</div>
                          <div style="margin: 0 2px">回复</div>
                          <div class="title">{{ element.fillerName }}</div>
                          <div class="date" style="margin-left: auto">
                            {{ child.createAt }}
                          </div>
                        </div>
                        <div class="content">
                          <div v-if="child.content">{{ child.content }}</div>
                          <template v-else>
                            <a-button type="primary" size="small" v-if="isMe(child.fillerId)"
                              @click="help(item.id, child.id)">协助</a-button>
                            <div v-else>暂未协助</div>
                          </template>
                        </div>
                      </div>
                    </template>
                  </template>
                </div>
              </template>
            </div>

            <div class="create-card" v-else>
              <Report v-if="isMe(item.userId)" :pid="detail.id" :item="item" @refresh="$emit('refresh')" />
              <div v-else class="not-write">
                <span style="margin-right: 12px">回款计划未填报</span>
                <div class="tag">
                  <DataDictFinder dictType="account_collect_status" :dictValue="item.status" />
                </div>
              </div>

              <div class="info">
                <div>{{ item.periodFrom }}</div>
                <div>回款计划</div>
              </div>
              <div class="index">
                {{ index + 1 }}
              </div>
              <img :src="arrow" class="arrow" alt="" />
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <a-empty />
      </template>

      <a-modal title="协助" :visible="helpVisible" :footer="null" @cancel="cancel">
        <a-form :form="form" :colon="false" @submit="handleSubmit">
          <a-form-item label="执行动态">
            <a-textarea placeholder="请输入" v-decorator="[
              'content',
              { rules: [{ required: true, message: '请输入！' }] },
            ]" :auto-size="{ minRows: 3 }" />
          </a-form-item>

          <div class="right">
            <a-space>
              <a-button @click="cancel">取消</a-button>
              <a-button type="primary" html-type="submit" :loading="saveLoading">保存</a-button>
            </a-space>
          </div>
        </a-form>
      </a-modal>
    </div>
  </div>
</template>

<script>
import Title from "../title";
import { fill } from "@/api/finance/contract-assets";
import { mapState } from "vuex";
import Report from "./report.vue";
export default {
  components: {
    Title,
    Report,
  },

  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
    list: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      icon: require("@/assets/accounts-receivable/icon-3.png"),
      arrow: require("@/assets/accounts-receivable/arrow-right.png"),

      pid: null, // 用户点击协助时处在的清收计划id，
      id: null, // 用户点击协助时的动态id
      // activeObj: {},
      helpVisible: false,
      form: this.$form.createForm(this),
      saveLoading: false,
    };
  },

  computed: {
    ...mapState("auth", ["user"]),
  },

  methods: {
    // 折叠
    fold(index) {
      this.list[index].fold = !this.list[index].fold;
      this.$forceUpdate();
    },
    // 渲染日期
    getDate(dateTimeStr) {
      return dateTimeStr && typeof dateTimeStr === "string"
        ? dateTimeStr.split(" ")[0]
        : "";
    },
    // 打开协助弹窗，让用户填
    help(pid, id) {
      this.helpVisible = true;
      this.pid = pid;
      this.id = id;
    },
    cancel() {
      this.helpVisible = false;
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.saveLoading = true;
          fill({
            ...values,
            collectId: this.pid,
            id: this.id,
          })
            .then(() => {
              this.form.resetFields();
              this.cancel();
              this.$emit("refresh");
            })
            .finally(() => {
              this.saveLoading = false;
            });
        }
      });
    },
    // 本人才可以填写
    isMe(id) {
      return this.user.uuid === id;
    },

    report(item) {
      this.activeItem = item;
      this.reportVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.plan {
  background-color: #fff;

  .plan-title {
    color: #1890ff;
    font-weight: bold;
    margin-bottom: 12px;
    font-size: 13px;
    margin-left: 20px;
  }
}

.list {
  position: relative;
  left: 140px;

  &::before {
    content: "";
    position: absolute;
    left: -70px;
    top: 0;
    bottom: 0;
    border-left: 2px solid #d7d7d7;
  }

  .item {
    margin-bottom: 20px;
  }
}

.primary-card {
  width: 433px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #f4f4f4;
  position: relative;

  &>.header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #1890ff;
    color: #fff;
    padding: 8px;
    font-weight: bold;
  }

  &>.content {
    background-color: #eaf5ff;
    padding: 8px;
    margin: 8px;

    .footer {
      .value {
        color: #ff8000;
        margin-right: 24px;
      }

      .fold-button {
        margin-left: auto;

        .icon {
          transform: rotateZ(-90deg);
        }

        .rotate {
          transform: rotateZ(90deg);
        }
      }
    }
  }

  &>.info {
    position: absolute;
    top: 0;
    left: -160px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &>.index {
    content: "";
    position: absolute;
    top: 6px;
    left: -80px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #1890ff;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    line-height: 1em;
  }

  &>.arrow {
    content: "";
    position: absolute;
    top: 10px;
    left: -50px;
  }
}

.default-card {
  background: #f4f4f4;
  border-radius: 5px;
  margin: 8px;

  position: relative;

  &>.header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 8px;
    color: #999;

    .title {
      color: #1890ff;
      font-weight: bold;
    }
  }

  &>.content {
    margin: 8px;
    padding-bottom: 8px;
  }

  &>.info {
    position: absolute;
    top: 0;
    left: -168px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &>.index {
    content: "";
    position: absolute;
    top: 6px;
    left: -84.5px;
    width: 14px;
    height: 14px;
    box-sizing: border-box;
    border-radius: 50%;
    border: 2px solid #1890ff;
    background-color: #fff;
  }

  &>.arrow {
    content: "";
    position: absolute;
    top: 7px;
    left: -60px;
  }
}

.tip {
  border-left: 8px solid #ff8000;
  margin: 8px;
  background: #f4f4f4;
  border-radius: 5px;
  padding: 12px 8px;
  position: relative;

  &>.info {
    position: absolute;
    top: 0;
    left: -168px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &>.index {
    content: "";
    position: absolute;
    top: 6px;
    left: -92px;
    width: 14px;
    height: 14px;
    box-sizing: border-box;
    border-radius: 50%;
    border: 2px solid #1890ff;
    background-color: #fff;
  }

  &>.arrow {
    content: "";
    position: absolute;
    top: 7px;
    left: -68px;
  }
}

.money {
  border-color: #52c41a;
}

.child {
  margin-left: 24px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: -18px;
    left: 24px;
    border: 10px solid transparent;
    border-bottom-color: #f4f4f4;
  }
}

.create-card {
  position: relative;

  .not-write {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 240px;
    background-color: #f4f4f4;
    padding: 8px;
  }

  &>.info {
    position: absolute;
    top: 0;
    left: -160px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &>.index {
    content: "";
    position: absolute;
    top: 6px;
    left: -80px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #1890ff;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    line-height: 1em;
  }

  &>.arrow {
    content: "";
    position: absolute;
    top: 10px;
    left: -50px;
  }
}

.tag {
  display: inline-block;
  padding: 4px 8px;
  background: #ff8000;
  text-align: center;
  opacity: 1;
  border-radius: 2px;
  color: #fff;
}
</style>