<template>
  <div id="pie"></div>
</template>

<script>
import * as echarts from "echarts/core";
import { TooltipComponent } from "echarts/components";
import { PieChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([TooltipComponent, PieChart, CanvasRenderer]);

export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },

  mounted() {
    var chartDom = document.getElementById("pie");

    chartDom.style.height = "120px";
    // chartDom.style.width = "36vw";
    this.chart = echarts.init(chartDom);
    this.renderData();
  },

  watch: {
    detail() {
      this.renderData();
    },
  },

  methods: {
    renderData() {
      var option = {
        color: ["#e0e0e0", "#1890ff"],
        tooltip: {
          trigger: "item",
        },

        series: [
          {
            name: "合同资产",
            type: "pie",
            radius: "90%",
            label: {
              show: false,
            },
            data: [
              { value: this.detail.notInvoiceSum, name: "未开票金额(元)" },
              { value: this.detail.invoiceSum, name: "已开票金额(元)" },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };

      this.chart && this.chart.setOption(option);
    },
  },
};
</script>