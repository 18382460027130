var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"base-info"},[_c('Title',{attrs:{"icon":_vm.icon,"title":"合同资产详情"}}),_c('div',{staticStyle:{"padding":"12px"}},[_c('a-row',{attrs:{"type":"flex","align":"middle"}},[_c('a-col',{attrs:{"span":18}},[_c('a-descriptions',{attrs:{"bordered":"","column":3,"size":"small"}},[_c('a-descriptions-item',{attrs:{"label":"工程名称","span":3}},[_vm._v(" "+_vm._s(_vm.detail.contractName)+" ")]),_c('a-descriptions-item',{attrs:{"label":"设计编号"}},[_vm._v(" "+_vm._s(_vm.detail.designCode)+" ")]),_c('a-descriptions-item',{attrs:{"label":"项目状态"}},[_vm._v(" "+_vm._s(_vm.detail.statusName)+" ")]),_c('a-descriptions-item',{attrs:{"label":"合同金额"}},[_c('Money',{attrs:{"money":_vm.detail.contractAmountNj}}),_vm._v("万元 ")],1),_c('a-descriptions-item',{attrs:{"label":"客户名称"}},[_vm._v(" "+_vm._s(_vm.detail.partyAname)+" ")]),_c('a-descriptions-item',{attrs:{"label":"产值进度"}},[_c('span',{staticStyle:{"color":"#1890ff"}},[_vm._v(" "+_vm._s(_vm.detail.projectProgress)+"% ")])]),_c('a-descriptions-item',{attrs:{"label":"项目类型"}},[_vm._v(" "+_vm._s(_vm.detail.projectTypeName)+" ")]),_c('a-descriptions-item',{attrs:{"label":"项目组合"}},[_vm._v(" "+_vm._s(_vm.detail.phases)+" ")]),_c('a-descriptions-item',{attrs:{"label":"合同收款结算进度"}},[_c('span',{staticStyle:{"color":"#1890ff"}},[_vm._v(" "+_vm._s(_vm.detail.receiptProgress)+"% ")])]),_c('a-descriptions-item',{attrs:{"label":"生产负责人"}},[_c('div',{staticClass:"left"},[_c('span',{staticStyle:{"margin-right":"4px"}},[_vm._v(" "+_vm._s(_vm.detail.productionMasterName)+" ")]),_c('EmployeeSelector',{on:{"change":(arr) => {
                                if (arr.length) {
                                  _vm.changeUser('productionMasterName', arr[0]);
                                }
                              }}},[(_vm.$getPermission(_vm.$route.path + '/edit'))?_c('a',{staticClass:"edit",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();}}},[_vm._v("修改")]):_vm._e()])],1)]),_c('a-descriptions-item',{attrs:{"label":"区域负责人"}},[_c('div',{staticClass:"left"},[_c('span',{staticStyle:{"margin-right":"4px"}},[_vm._v(" "+_vm._s(_vm.detail.areaMasterName)+" ")]),_c('EmployeeSelector',{on:{"change":(arr) => {
                                if (arr.length) {
                                  _vm.changeUser('areaMasterName', arr[0]);
                                }
                              }}},[(_vm.$getPermission(_vm.$route.path + '/edit'))?_c('a',{staticClass:"edit",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();}}},[_vm._v("修改")]):_vm._e()])],1)]),_c('a-descriptions-item',{attrs:{"label":"现场负责人"}},[_c('div',{staticClass:"left"},[_c('span',{staticStyle:{"margin-right":"4px"}},[_vm._v(" "+_vm._s(_vm.detail.siteLeaderName)+" ")]),_c('EmployeeSelector',{on:{"change":(arr) => {
                                if (arr.length) {
                                  _vm.changeUser('siteLeader', arr[0]);
                                }
                              }}},[(_vm.$getPermission(_vm.$route.path + '/edit'))?_c('a',{staticClass:"edit",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();}}},[_vm._v("修改")]):_vm._e()])],1)]),_c('a-descriptions-item',{attrs:{"label":"分管院长"}},[_c('div',{staticClass:"left"},[_c('span',{staticStyle:{"margin-right":"4px"}},[_vm._v(" "+_vm._s(_vm.detail.chargeMasterName)+" ")]),_c('EmployeeSelector',{on:{"change":(arr) => {
                                if (arr.length) {
                                  _vm.changeUser('chargeMaster', arr[0]);
                                }
                              }}},[(_vm.$getPermission(_vm.$route.path + '/edit'))?_c('a',{staticClass:"edit",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();}}},[_vm._v("修改")]):_vm._e()])],1)]),_c('a-descriptions-item',{attrs:{"label":"主要负责"}},[_c('div',{staticClass:"left"},[(_vm.detail.masterId === _vm.detail.productionMaster)?_c('span',{staticStyle:{"margin-right":"4px"}},[_vm._v(" "+_vm._s(_vm.detail.productionMasterName)+" ")]):_vm._e(),(_vm.detail.masterId === _vm.detail.areaMaster)?_c('span',{staticStyle:{"margin-right":"4px"}},[_vm._v(" "+_vm._s(_vm.detail.areaMasterName)+" ")]):_vm._e(),(_vm.$getPermission(_vm.$route.path + '/edit'))?_c('EditMaster',{attrs:{"detail":_vm.detail},on:{"refresh":function($event){return _vm.$emit('refresh')}}}):_vm._e()],1)])],1)],1),_c('a-col',{attrs:{"span":6}},[_c('Chart',{attrs:{"detail":_vm.detail}}),_c('div',{staticClass:"list"},[_c('div',{staticClass:"left item"},[_c('div',{staticClass:"legend",staticStyle:{"background":"#1890ff"}}),_c('span',[_vm._v(" 已开票金额： ")]),_c('span',{staticStyle:{"min-width":"4vw"}},[_c('Money',{attrs:{"money":_vm.detail.invoiceSum}})],1),_c('span',[_vm._v(" 万元 ")])]),_c('div',{staticClass:"left item"},[_c('div',{staticClass:"legend",staticStyle:{"background":"#f0f0f0"}}),_c('span',[_vm._v(" 未开票金额： ")]),_c('span',{staticStyle:{"min-width":"4vw"}},[_c('Money',{attrs:{"money":_vm.detail.notInvoiceSum}})],1),_c('span',[_vm._v(" 万元 ")])])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }